
import SortingThumbnail from "./assets/Sorting_Thumb.png"
import StockThumbnail from "./assets/stock_thumb.jpg"
import FootprintThumbnail from "./assets/footprint_thumb.png"
import PriceThumbnail from "./assets/download.jpg"

let projectDetails = [
    {
        title: 'Sorting Algorithm Visualizer',
        image: SortingThumbnail,
        shortDescription: 'Flask App to help beginner programmers visual simple and complex sorting algorithms',
        longDescription: 'The Sorting Algorithm Visualizer is a web app written in vanilla JavaScript and served with Flask, it is meant to help a useful aid to help programmers visualize common sorting algorithms. The tool gives a step by step animation of how arrays with values of varrying length are sorted in the following algorithms: Bubble Sort, Insertion Sort, Quick Sort, Merge Sort',
        tags: ['Python', 'JavaScript'],
        demo: 'https://sort.nisargshah.dev',
        github: 'https://github.com/Nisarg-S/Sorting-Algorithm-Visualizer'
    },
    {
        title: 'Price Watcher',
        image: PriceThumbnail,
        shortDescription: 'Python cronjob which tracks prices of various items accross major retailers.',
        longDescription: 'The Price Watcher is a cronjob written in Python which tracks the prices of user selected items across Amazon and Newegg. The cronjob keeps track of weekly, monthly and all-time low prices, notifying the user through text and email when the price of an item hits a weekly/monthly/all-time low.',
        tags: ['Python'],
        demo: false,
        github: 'https://github.com/Nisarg-S/Price-Watcher'
    },
    {
        title: 'Stock Market Tracker',
        image: StockThumbnail,
        shortDescription: 'Python cronjob which tracks stocks across various world markets',
        longDescription: 'The Stock Market Tracker is a cronjob written in Python which fetches stock data from the Yahooo Finance API and notifies its users when stocks on their watch list cross predefined threshold values.',
        tags: ['Python'],
        demo: false,
        github: 'https://github.com/Nisarg-S/Stock-Notifier'
    },
    {
        title: 'Carbon Footprint Tracker',
        image: FootprintThumbnail,
        shortDescription: 'A desktop app which can be used to montior and track a users total carbon footprint',
        longDescription: 'The Carbon Footprint Tracker is a python app made using the Tkinter GUI library used to log a persons daily activities(i.e travelling, leisure etc.) and calculate their CO2 emissions using the Cool Climate API',
        tags: ['Python'],
        demo: false,
        github: 'https://github.com/Nisarg-S/Carbon-Footprint-Tracker'
    },
]



export default projectDetails